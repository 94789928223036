import React from 'react';
import Form from './form';

const BannersForm = () => {
    const formId = '1bcae1a9-f5cf-48a6-a378-95ef1aac468f';

    return (
        <Form formId={formId} />
    )
}
export default BannersForm;
